shopJS.prod = {
    init() {
        $d.product.isInStock = false;
        $d.product.isAllInStock = false;
        $d.product.curMultiTab = 'normal';

        shopJS.static.productStatic();
        shopJS.static.categoryStatic();
        shopJS.prod.initInStockProduct();
        shopJS.prod.initPositioningBar();
        shopJS.prod.sliderInit();
        shopJS.prod.bindToReviews();
        shopJS.prod.sliderModal();
        shopJS.prod.selectColor();
        shopJS.prod.selectThumb();
        shopJS.prod.selectSize();
        shopJS.prod.toggleSizeDetailTips();
        shopJS.prod.customSize();
        shopJS.prod.showDetail();
        shopJS.prod.qtyInit();
        shopJS.prod.addCart();
        shopJS.prod.review();
        shopJS.prod.attachImageInit();

        shopJS.prod.modalOpen();
        shopJS.prod.limitShowColor();
        shopJS.prod.bindLike();
        shopJS.prod.sendShareEmail();
        shopJS.prod.videoPlayInit();

        shopJS.googleAds.googleAds('product', shopJS.common.getSnFromUrl(), shopJS.prod.getProductSellPrice());
        shopJS.prod.reportGaDetail();

        // facebook pixel for product page
        if (shopJS.facebook.status) {
            var price = shopJS.prod.getProductSellPrice();
            var currency = shopJS.prod.getCurrentCurrency();
            var sn = shopJS.common.getSnFromUrl();
            var content_ids = [sn];
            var content_type = 'product';
            shopJS.facebook.viewContent(price, currency, content_ids, content_type);
        }
        // facebook pixel for product page
    },


    //header show
    initPositioningBar(opt) {
        const $bar = $('.positioning-bar');
        const $Item = $bar.find('.item');
        const $ShowMarkEl = $('.prod-select');
        const $ReviewEl = $('.review-box');
        const $RecommendEl = $('.recommend-list');
        const topOffset = 150;
        const throttleDelayTime = 100;

        let elScroll = getElScroll();
        let isClickNav = false;

        if (opt && opt.exec === 'scroll') {
            handlerScroll();
            return;
        }

        $Item.on('click', function () {
            let $this = $(this);
            let index = $this.data('index');
            let targetScroll

            elScroll = getElScroll();
            $this.addClass('on').siblings('.item').removeClass('on');

            switch (index) {
                case 1:
                    targetScroll = elScroll.goodsEl;
                    break;
                case 2:
                    targetScroll = elScroll.reviewsEl;
                    break;
                case 3:
                    targetScroll = elScroll.recommendEl;
                    break;
                default:
                    return
            }

            isClickNav = true;
            $('html,body').animate({
                scrollTop: targetScroll - topOffset
            }, 300, function () {
                setTimeout(() => {
                    isClickNav = false;
                }, throttleDelayTime + 50)
            });
        });

        $(window).on('scroll', $u.throttle(this, handlerScroll, throttleDelayTime));

        function handlerScroll() {
            let scrollTop = $(window).scrollTop();

            scrollTop += topOffset

            if (isClickNav) return

            elScroll = getElScroll();

            if (scrollTop > elScroll.showMarkEl) {
                $bar.addClass('fixed')
            } else {
                $bar.removeClass('fixed')
                return;
            }

            if ($('header').hasClass('in')) {
                $bar.addClass('under_header')
            } else {
                $bar.removeClass('under_header')
            }

            if (scrollTop < elScroll.reviewsEl) {
                setCurNavItem(1)
            } else if (scrollTop >= elScroll.reviewsEl && scrollTop < elScroll.recommendEl) {
                setCurNavItem(2)
            } else if (scrollTop >= elScroll.recommendEl) {
                setCurNavItem(3)
            }
        }

        function getElScroll() {
            return {
                goodsEl: 0,
                reviewsEl: $ReviewEl.offset().top,
                recommendEl: $RecommendEl.offset().top,
                showMarkEl: $ShowMarkEl.offset().top,
            }
        }

        function setCurNavItem(index) {
            $Item.eq(index - 1).addClass('on').siblings('.item').removeClass('on');
        }
    },

    getProductSellPrice: function () {
        return $('meta[property="og:price:amount"]').attr('content');
    },
    getCurrentCurrency: function () {
        return $('meta[property="og:price:currency"]').attr('content');
    },

    setShownColorImage: function (){
        setTimeout(function(){
            let current_image = $('#prod-slider').find('.swiper-slide-active img').attr('src');
            let asShown = $('.select-color.normal a.As_Shown');
            if(current_image && asShown){
                asShown.css("background-image","url(" + current_image + ")")
                    .css("background-position","0 0")
                    .css("background-size","40px,72px")
            }
        },100)
    },

    //product slider
    prodSlider: null,
    gallery: null,
    hasVideoImg: $('li.video-cover-image').length > 0,
    sliderInit() {
        shopJS.prod.prodSlider = new Swiper('#prod-slider', {
            loop: true,
            spaceBetween: 10,
            pagination: {
                type: 'fraction',
                el: '.prod-slider .swiper-pagination',
                renderFraction: function (currentClass, totalClass) {
                    return `<div class="cur ${currentClass}"></div>
                        <div class="total ${totalClass}"></div>
                        `;
                },
            },
            on: {
                afterInit: function () {
                    shopJS.prod.setShownColorImage()
                },
                tap: function () {
                    let index = this.activeIndex;
                    if (shopJS.prod.hasVideoImg) {
                        index -= 1;
                    }
                    $('#img-slider').data('index', index);
                },
                activeIndexChange: function(){
                    shopJS.prod.setShownColorImage()
                }
            }
        });

        $('.prod-slider ul li img').one('load', function () {
            shopJS.prod.prodSlider.updateSlides();
        });

        $('#prod-slider .swiper-slide').on('click', function () {
            const $this = $(this);
            if (!$this.hasClass('video-cover-image')) {
                $('#pro-img').css({height: window.innerHeight + 'px'}).modal('show');
            }
        })
    },

    sliderModal() {
        const $proImg = $('#pro-img');
        const $imgSlide = $('#img-slider');

        const thumbSwiper = new Swiper('#img-slider-thumb', {
            width: window.innerWidth,
            spaceBetween: 6,
            slidesPerView: 5,
            watchSlidesVisibility: true,
        });

        let options = {
            loop: true,
            spaceBetween: 10,
            width: window.innerWidth,
            preloadImages: false,
            zoom: {
                enabled: true,
            },
            thumbs: {
                swiper: thumbSwiper,
                autoScrollOffset: 2,
            },
            // on: {
            //     click: function () {
            //         $proImg.modal('hide');
            //     }
            // }
        };

        if ($imgSlide.find('.img-wisp').length > 0) {
            delete options.zoom;
        }

        const imgSwiper = new Swiper('#img-slider', options);

        $proImg.on('show.bs.modal', function () {
            let index = $imgSlide.data('index');
            //imgSlider.update();
            imgSwiper.slideTo(index, 0, false);
        });

        $proImg.on('hide.bs.modal', function () {
            let index = $imgSlide.find('.swiper-slide-active').index();
            if (shopJS.prod.hasVideoImg) {
                index += 1;
            }
            // console.log(index);
            shopJS.prod.prodSlider.slideTo(index, 0, false);
        });
    },

    videoPlayInit() {
        let $outPlayBtn = $('.video-cover-image .btn-play');
        let $closeVideoBtn = $('.btn-close-video');
        let $videoWrap = $('.big-video-wrap');
        let videoEl = $videoWrap.find('video')[0];

        $outPlayBtn.on('click', function () {
            $videoWrap.removeClass('hide');
            videoEl.play();
        })

        $closeVideoBtn.on('click', function () {
            videoEl.pause();
            $videoWrap.addClass('hide');
        })

    },

    selectThumb() {
        $('.select-thumb .thumb-list').on('click', '.item', function (e) {
            var $this = $(this);
            var $selectThumb = $this.closest('.select-thumb');
            var thumbIndex = $(this).data('index');
            $this.addClass('selected').siblings('.item').removeClass('selected');
            $selectThumb.find('h2 .selected').text($this.data('color'));
            shopJS.prod.prodSlider.slideToLoop(thumbIndex, 0, false);
            shopJS.prod.switchSkuStyle();
        })
    },

    setChoseThumb() {
        var $choseThumb = $('.js-thumb-list').find('.selected');
        var $flag = $('.js-as-picture');
        var $indexInput = $('#attr_pInx');
        var $colorInput = $('#attr_color');
        if ($flag.length > 0 && $flag.val() == 1 && $choseThumb.length > 0) {
            $indexInput.val($choseThumb.data('index'));
            $colorInput.val($choseThumb.data('value'));
        }
    },

    initInStockProduct() {
        let $productBaseInfo = $(".product-main");
        let inStock = $productBaseInfo.data('in-stock');
        let allInStock = $productBaseInfo.data('all-in-stock');

        $d.product.isInStock = inStock === 1;
        $d.product.isAllInStock = allInStock === 1;

        let $mulitSelectTab = $('.multi-select-tab-box');
        if ($mulitSelectTab.length > 0) { //数据初始化
            shopJS.prod.switchMultiSelectTab();
        }
    },

    switchMultiSelectTab() {
        let $list = $('.multi-select-tab-box');
        let $item = $list.find('.tab-item');

        $item.on('click', function () {
            let $this = $(this);
            let type = $this.data('type');
            $this.addClass('cur').siblings().removeClass('cur');
            if (type === 'in-stock') {
                $('.select-box-wrap').addClass('hide').filter('.in-stock').removeClass('hide')
                $d.product.curMultiTab = 'in-stock'
            } else {
                $('.select-box-wrap').addClass('hide').filter('.normal').removeClass('hide')
                $d.product.curMultiTab = 'normal'
            }
            shopJS.prod.checkInStock();
        })

        $item.filter('.in-stock').trigger('click');
    },

    attachImageInit() {
        let $attachImageModal = $('#attach-image-modal');
        let $attachImageModalImage = $("#attach-image-modal-image");
        $attachImageModal.on('show.bs.modal', function (e) {
            $attachImageModalImage.attr('src', e.relatedTarget.src);
        });

        $attachImageModalImage.on("click", function () {
            $attachImageModalImage.attr('src', '');
            $attachImageModal.modal('hide');
        });
    },

    // api返回spec后再触发点击事件
    initInStockData() {
        let $colorBox = $('.select-color.in-stock');
        let $colorItem = $colorBox.find('.color-list li')
        let $sizeBox = $('.select-size.in-stock');

        $colorItem.on('click', function () {
            let $this = $(this);
            let color = $this.find('a').data('value');
            $this.addClass('selected').siblings().removeClass('selected');
            $this.parents('.select-color').find('h2 .selected').text(color);
            shopJS.prod.buildInStockSize();
            shopJS.prod.checkInStock();
        })

        $sizeBox.on('click', '.size-list-wrap li', function () {
            let $this = $(this);
            $this.addClass('selected').siblings().removeClass('selected');
            if ($this.data('table')) {
                shopJS.prod.setSizeTable($('.js-size-detail.in-stock'), $this.data('table'));
            }
            shopJS.prod.checkInStock();
        })

        // set default for first color;
        $colorItem.first().trigger('click');
    },

    buildInStockSize() {
        let curColor = $('.select-color.in-stock .color-list li.selected a').data('value');
        let $sizeList = $('.select-size.in-stock .size-list-wrap ul');
        let curSpecs = $d.product.detail.specs.filter(i => {
            return i.color === curColor
        })

        /* 获取上次选中的尺码-》size 4 ->第一个尺码 */
        let preSelectSize = $sizeList.find('.item.selected').text().trim();
        let targetSize = curSpecs.some(i => i.size === preSelectSize) ? preSelectSize
            : curSpecs.some(i => i.size === "10") ? '10' : curSpecs[0].size;
        let html = '';
        curSpecs.forEach((i, index) => {
            let table = $('.select-size.normal .size-list-wrap .item').filter(function (index, item) {
                // console.log(item);
                return $(item).attr('value') == i.size
            }).data('table')

            table = table ? JSON.stringify(table) : ''
            html += `
                <li class="item ${targetSize.trim() === i.size.trim() ? 'selected' : ''}" value="${i.size}"
                    data-size="${i.size}" data-table='${table}'>${i.size}
                </li>
                `
        })

        for (let i = 0; i < 9; i++) {
            html += '<li class="item empty-li"></li>'
        }

        $sizeList.html(html);

        $sizeList.find('.item.selected').first().trigger('click');
    },

    selectColor() {
        $('.select-color.normal li a').on('click', function (e) {
            e.preventDefault();

            const $this = $(this);
            const $parent = $this.parents('li')
            const $selectColor = $this.parents('.select-color');
            if ($d.product.detail.type === 'fabricsample') {
                $parent.toggleClass('selected');
                let colorArr = $selectColor.find('li.selected a').map(function () {
                    return $(this).data('value')
                }).get();
                $selectColor.find('h2 .selected').text(colorArr.join(', ')); // 标题中颜色显示
                $selectColor.find('input').val(JSON.stringify(colorArr)); // 设定购物车数据
                // console.log(colorArr);
            } else {
                $parent.addClass('selected').siblings('li').removeClass('selected');
                $selectColor.find('h2 .selected').text($this.data('value')); // 标题中颜色显示
                $selectColor.find('input').val($this.data('value')); // 设定购物车数据
                shopJS.prod.checkInStock();
            }
        })


        var urlParams = $u.getUrlParams();
        // console.log(urlParams);
        if (urlParams.color) {
            $('.select-color li a[data-lower-value="' + urlParams.color + '"]').trigger('click');
        }

    },

    isSKUProd() {
        return $('.js-thumb-list.is-sku-prod').length > 0 ? true : false;
    },

    switchSkuStyle() {
        if (shopJS.prod.isSKUProd()) {
            var $choseThumb = $('.js-thumb-list').find('.selected');
            $('.js-prod-price .price-value').text($choseThumb.data('price'));
        }
    },

    selectSize() {
        $('.select-size.normal .size-list-wrap .item ').on('click', function () {
            let $this = $(this);
            $this.addClass('selected').siblings().removeClass('selected');
            $('#attr_size').val($this.data('size'));
            if ($this.data('table')) {
                $('.size-detail-tips').removeClass('hide');
                shopJS.prod.setSizeTable($('.js-size-detail.normal'), $this.data('table'));
            }
            $('.js-custom-size').removeClass('selected');
            $(".custom-size-service-fee").html('').addClass('hide')
            // $('.js-custom-size-detail').addClass('hide');
            shopJS.prod.checkInStock();
        });

        let urlParams = $u.getUrlParams();
        // console.log(urlParams);
        if (urlParams.size) {
            $('.size-list-wrap').find('.item[data-size="' + urlParams.size + '"]').trigger('click');
        }
    },

    buildCustomSizeData(size) {
        if (typeof size == 'string') {
            size = JSON.parse(size);
        }
        let sizeObj = {};

        for (let key in size) {
            sizeObj[key] = {
                'inch': size[key],
                'cm': inch2cm(size[key])
            }
        }

        return sizeObj;

        function inch2cm(num) {
            const rate = 2.54;
            return Math.round(num * rate)
        }
    },

    showDetail() {
        $(".toggle-container-header").on('click', function () {
            let container = $(this).parents(".toggle-container-box");
            let contentBox = container.find(".toggle-container-content");
            let icon = $(this).find("i");
            let status = contentBox.hasClass('hide');
            if (status) {
                contentBox.removeClass('hide');
                icon.addClass("open")
            } else {
                contentBox.addClass('hide');
                icon.removeClass("open")
            }
        })
    },

    toggleSizeDetailTips() {
        $('.size-detail-tips .btn_more').on('click', function () {
            let $this = $(this);
            let txt = $this.text();
            if (txt !== 'less') {
                $('.size-detail-tips .text2').fadeIn()
                $this.text('less')
            } else {
                $('.size-detail-tips .text2').fadeOut()
                $this.text('more')
            }
        })
    },

    setSizeTable($sizeDetailBox, size) {
        if (!size) {
            return '';
        }

        let sizeStr = '';
        let inchStr = '';
        let cmStr = '';

        for (let k in size) {
            inchStr += '<li><span class="key">' + k + ':</span><span class="value">' + size[k].inch + '</span></li>';
            cmStr += '<li><span class="key">' + k + ':</span><span class="value">' + size[k].cm + '</span></li>';
        }

        inchStr = '<div class="inch"><div class="title">Inch</div><ul>' + inchStr + '</ul></div>';
        cmStr = '<div class="cm"><div class="title">cm</div><ul>' + cmStr + '</ul></div>';

        sizeStr = inchStr + cmStr;

        $sizeDetailBox.removeClass('hide').find('.size-detail-inner').html(sizeStr);
        // $('.js-custom-size-detail').addClass('hide');
    },

    calculateCustomSizeFee(value, unit = 'inch') {
        let feeRangeInch = [
            {min: 56.5, max: 58, price: 20},
            {min: 58.5, max: 60, price: 30},
            {min: 60.5, max: 62, price: 40},
            {min: 62.5, max: 64, price: 50},
            {min: 64.5, max: 66, price: 60},
            {min: 66.5, max: 68, price: 70},
            {min: 68.5, max: 70, price: 80},
            {min: 70.5, max: 72, price: 90},
            {min: 72.5, max: 74, price: 100}
        ];
        let feeRangeCm = [
            {min: 143, max: 147, price: 20},
            {min: 148, max: 152, price: 30},
            {min: 153, max: 157, price: 40},
            {min: 158, max: 162, price: 50},
            {min: 163, max: 167, price: 60},
            {min: 168, max: 173, price: 70},
            {min: 174, max: 178, price: 80},
            {min: 179, max: 183, price: 90},
            {min: 184, max: 188, price: 100}
        ];

        let fee = 0;
        let feeRange = feeRangeInch

        if (unit === 'cm') {
            feeRange = feeRangeCm
        }

        if (value < feeRange[0].min) {
            return 0;
        }

        if (value > feeRange[feeRange.length - 1].max) {
            return feeRange[feeRange.length - 1].price
        }

        for (let i = 0; i <= feeRange.length - 1; i++) {
            if (value >= feeRange[i].min && value <= feeRange[i].max) {
                fee = feeRange[i].price;
                break;
            }
        }

        return fee;
    },

    customSize() {
        const $customModal = $('#custom-modal');
        const $customSizeFeeBox = $('.custom-size-fee');
        let unit = 'inch';
        let customSizeCalValue = 0;
        let customSizeFee = 0;
        let pid = $customModal.data('pid')
        let feeStatus = $customModal.data('feeStatus')

        let bustValue = 0;
        let waistValue = 0;
        let hipsValue = 0;
        let customSizeFeeIntroModal = $("#custom-size-fee-intro-modal")
        // let customSizeFeeIntroModalCloseButton = customSizeFeeIntroModal.find(".btn-close");
        // let customSizeModalCloseButton = $customModal.find(".btn-close");
        let customSizeModelCanBeClose = true;

        customSizeFeeIntroModal.on('show.bs.modal', function () {
            customSizeModelCanBeClose = false;
        })

        $customModal.on('hide.bs.modal', function (e) {
            if (customSizeModelCanBeClose === false) {
                e.preventDefault();
            }
            customSizeModelCanBeClose = true;
        })

        $customModal.on('change', 'select', function () {
            $(this).addClass('selected').children('.placeholder').hide();

            if(feeStatus === "1"){
                let attrName = $(this).attr('name');

                if (attrName === 'bust') {
                    bustValue = $(this).val();
                }

                if (attrName === 'waist') {
                    waistValue = $(this).val();
                }

                if (attrName === 'hips') {
                    hipsValue = $(this).val();
                }

                customSizeCalValue = Math.max(bustValue, waistValue, hipsValue)

                customSizeFee = shopJS.prod.calculateCustomSizeFee(customSizeCalValue, unit)

                if (customSizeFee > 0) {
                    $customSizeFeeBox.removeClass("hide").find(".fee").html('US$ ' + customSizeFee)
                } else {
                    $customSizeFeeBox.addClass("hide").find(".fee").html()
                }
            }
        });

        $customModal.on('click', '.custom-unit-trans', function () {
            $('.unit-form').addClass('hide');
            let $from = $('.js-' + $(this).text().toLowerCase() + '-form');
            shopJS.prod.customFromReset($from);
            $from.removeClass('hide');

            $('.custom-unit-trans').removeClass('on');
            $(this).addClass('on');
            unit = $(this).text().toLowerCase();
        });

        var confirmCount = 0;
        $customModal.on('click', '.btn-custom-confirm', function (e) {
            e.preventDefault();
            let flag = true;
            const $unitFrom = $('.js-' + unit + '-form');
            $.each($unitFrom.find('select'), function (index, element) {
                if (!$(element).hasClass('belly')) {
                    if ($(element).val() == '') {
                        shopJS.common.errorInput($(this));
                        flag = false;
                    } else {
                        shopJS.common.rightInput($(this));
                    }
                }
            });

            // belly
            const $belly = $unitFrom.find('.form-group select.belly');
            if ($unitFrom.find('.pregnant').is(':checked')) {
                if ($belly.val() === '') {
                    shopJS.common.errorInput($belly);
                    flag = false;
                } else {
                    shopJS.common.rightInput($belly);
                }
            } else {
                shopJS.common.rightInput($belly);
                // $belly.val(0)
            }

            let passCheck = shopJS.prod.customFromCheck($unitFrom);
            if (!passCheck) {
                confirmCount++;
            }
            if (flag && (passCheck || confirmCount > 1)) {
                let size = shopJS.prod.getCustomFormValue($unitFrom);
                // console.log(size)
                confirmCount = 0;
                $('.size-detail-tips').addClass('hide');
                shopJS.prod.setSizeTable($('.js-size-detail.normal'), shopJS.prod.buildCustomSizeData(size));
                $('#attr_size').val('');
                $('.select-size.normal .size-list-wrap .item').removeClass('selected')
                $('.js-custom-size').addClass('selected').data('size', JSON.stringify(size));
                $('#custom-modal').modal('hide');

                // request custom service Api
                let customSizeCalData = {
                    bust: bustValue,
                    waist: waistValue,
                    hips: hipsValue
                }

                let post = {
                    pid: pid,
                    data: customSizeCalData
                }
                shopJS.prod.customSizeCheck(post)
            }

            $('.in-stock-tips').addClass('hide').text('');
            shopJS.prod.setETAInfo($d.product.detail.eta)
        });
    },

    getCustomFormValue($form) {
        let size = {};
        const rate = $form.data('unit') === 'cm' ? 2.54 : 1;
        size.Bust = shopJS.prod.sizeFormat(parseFloat($form.find('.bust').val()) / rate);
        size.Waist = shopJS.prod.sizeFormat(parseFloat($form.find('.waist').val()) / rate);
        size.Hips = shopJS.prod.sizeFormat(parseFloat($form.find('.hips').val()) / rate);
        size['Hollow To Floor'] = shopJS.prod.sizeFormat(parseFloat($form.find('.hollow').val()) / rate);
        size.Height = shopJS.prod.sizeFormat(parseFloat($form.find('.height').val()) / rate);
        size.Armhole = shopJS.prod.sizeFormat(parseFloat($form.find('.armhole').val()) / rate);
        if ($form.find('.pregnant').is(':checked')) {
            size.Belly = shopJS.prod.sizeFormat(parseFloat($form.find('select.belly').val()) / rate);
        }
        return size;
    },

    customFromCheck($form) {
        let size = shopJS.prod.getCustomFormValue($form);
        let $topTips = $form.find('.custom-error-tip');
        let unit = $form.data('unit');
        let rate = $form.data('unit') === 'inch' ? 1 : 2.54;
        let range = Math.round(8 * rate).toString() + '~' + Math.round(14 * rate).toString() + ' ' + unit;

        let waistErr = 'Please double check your Bust, Waist and Hips measurements. ' +
                'If you are unable to place an order with your exact measurements, ' +
                'please contact our customer service.',
            heightErr = 'Height minus Hollow to Floor should be ' + range + ', ' +
                'please double check the two measurements.',
            doubleErr = 'Height minus Hollow to Floor should be ' + range + ', ' +
                'please double check the two measurements.' +
                'Please double check your Bust, Waist and Hips measurements. ' +
                'If you are unable to place an order with your exact measurements, ' +
                'please contact our customer service.';

        if (size.Waist > size.Bust || size.Waist > size.Hips) {
            $topTips.removeClass('hide').addClass('waist-err').text(waistErr);
        } else {
            $topTips.addClass('hide').removeClass('waist-err');
        }

        if (size.Height - size['Hollow To Floor'] < 8 || size.Height - size['Hollow To Floor'] > 14) {
            if ($topTips.hasClass('waist-err')) {
                $topTips.removeClass('hide').text(doubleErr);
            } else {
                $topTips.removeClass('hide').text(heightErr);
            }
            return false;
        } else if ($topTips.hasClass('waist-err')) {
            return false;
        } else {
            $topTips.addClass('hide');
        }
        return true;
    },

    customFromReset($form) {
        let $selects = $form.find('select');
        shopJS.common.rightInput($selects);
        $selects.children('.placeholder').show();
        $selects.val('');
    },

    customSizeCheck(data) {
        $u.http.post('/api/product/custom-size-check', data).then(res => {
            // console.log(res)
            if (res.status === 200) {
                let data = res.data.data;
                let customSizeServiceFeeSpan = $(".custom-size-service-fee");
                customSizeServiceFeeSpan.html("<span class='size-choose'>Custom Size </span><span class='fee'>(" + data.symbol + ' ' + data.price + ")</span>").removeClass('hide');
            }
        })
    },

    qtyInit() {
        let $qty = $('.js-product-qty');
        let $input = $qty.find('input');
        let sellMin = $input.data('sell-min');
        let sellMax = $input.data('sell-max');

        $qty.on('click', '.btn-add', function () {
            let qty = parseInt($input.val()) + 1;
            if (qty <= 0) {
                qty = 1;
            }
            if (sellMax > 0 && qty > sellMax) {
                qty = sellMax;
            }
            $input.val(qty);
        });

        $qty.on('click', '.btn-minus', function () {
            let qty = parseInt($input.val()) - 1;
            if (qty < 1) {
                qty = 1;
            }

            if (sellMin > 0 && qty < sellMin) {
                qty = sellMin;
            }
            $input.val(qty);
        });

        $qty.on('blur', '#qty', function () {
            let qty = parseInt($input.val());
            if (qty < 1 || isNaN(qty)) {
                qty = 1;
            }

            if (sellMin > 0 && qty < sellMin) {
                qty = sellMin;
            }

            if (sellMax > 0 && qty > sellMax) {
                qty = sellMax;
            }

            $input.val(qty);
        });
    },

    sizeFormat(value) {
        let intPart = Math.floor(value);
        let decimal = value - intPart;

        let formatDecimal = 0;
        if (0.125 <= decimal && decimal < 0.375) {
            formatDecimal = 0.25;
        } else if (0.375 <= decimal && decimal < 0.625) {
            formatDecimal = 0.50;
        } else if (0.625 <= decimal && decimal < 0.875) {
            formatDecimal = 0.75;
        } else if (decimal >= 0.875) {
            formatDecimal = 1;
        }

        return intPart + formatDecimal;
    },

    getNormalToCartData() {
        let data = {
            pid: $('#pid').val(),
            qty: $('#qty').val(),
            type: $('.btn-add-cart').data('type')
        };
        let productData = {}
        let error = 0;

        let spec = shopJS.prod.checkInStock();

        if ($d.product.curMultiTab === 'in-stock') {
            productData['Color'] = spec.color;
            productData['Size'] = spec.size;
        } else {
            shopJS.prod.setChoseThumb();
            let $attrColor = $('#attr_color');
            if ($attrColor.length) {
                let $colorWarn = $('.js-color-warn');
                productData['Color'] = $attrColor.val() ? $attrColor.val() : null;
                if (!productData['Color']) {
                    error += 1;
                    $colorWarn.removeClass('hide');
                } else {
                    $colorWarn.addClass('hide');
                }

                if (productData['Color'] === 'As picture') {
                    let $imgIndex = $('#attr_pInx');
                    if ($imgIndex.length) {
                        productData['Img_key'] = $imgIndex.val();
                    }
                }
            }

            let $attrBeltColor = $('#attr_beltcolor');
            if ($attrBeltColor.length) {
                let $beltColorWarn = $('.js-belt-color-warn');
                productData['Belt Color'] = $attrBeltColor.val();
                if (productData['Belt Color'] === '') {
                    error += 1;
                    $beltColorWarn.removeClass('hide');
                } else {
                    $beltColorWarn.addClass('hide');
                }
            }

            let $attrSize = $('#attr_size');
            let $customSizeWrap = $('.js-custom-size');
            let $sizeWarn = $('.js-size-warn');
            if ($attrSize.length) {
                productData['Size'] = $attrSize.val();
                if (productData['Size'] === '' && (!$customSizeWrap.length || !$customSizeWrap.hasClass('selected'))) {
                    error += 1;
                    $sizeWarn.removeClass('hide');
                } else {
                    $sizeWarn.addClass('hide');
                }
            }

            if ($customSizeWrap.length) {
                if ($customSizeWrap.hasClass('selected')) {
                    productData['Size'] = 'Custom Size';
                    let size = JSON.parse($customSizeWrap.data('size'));
                    productData["Bust (Inch)"] = size.Bust;
                    productData["Waist (Inch)"] = size.Waist;
                    productData["Hips (Inch)"] = size.Hips;
                    productData["Height (Inch)"] = size.Height;
                    productData["Armhole (Inch)"] = size.Armhole;
                    productData["Hollow To Floor (Inch)"] = size['Hollow To Floor'];
                    if (size['Belly']) {
                        productData["Belly (Inch)"] = size['Belly'];
                    }

                    $sizeWarn.addClass('hide');
                } else if (!productData['Size']) {
                    error += 1;
                    $sizeWarn.removeClass('hide');
                    // return;
                } else {
                    $sizeWarn.addClass('hide');
                }
            }

            if (error > 0) {
                $('html,body').animate({
                    scrollTop: ($('.prod-select .content').offset().top) - 120
                }, 200);
                return false;
            }
        }

        const service = $('.product-service:checked');
        if (service.length) {
            productData['service'] = [];
            $(service).each(function (k, v) {
                productData['service'].push($(v).val());
            });
        }

        $.each($('.product-data'), function (index, element) {
            var $element = $(element);
            productData[$element.attr('name')] = $element.val();
        });

        productData['image'] = $('#prod-slider').find('.swiper-slide-active img').data('name') || '',

            data['data'] = productData;
        if (spec) {
            data['spec_id'] = spec.id;
        }

        return data
    },

    getFabricToCartData() {
        let products = [], productBaseData = {}, colors = [], error = 0;
        let baseData = {
            pid: $('#pid').val(),
            qty: $('#qty').val()
        };

        let $attrColor = $('#attr_color');
        let $colorWarn = $('.js-color-warn');
        colors = $attrColor.val() ? JSON.parse($attrColor.val()) : [];
        // console.log(colors)
        if (colors.length) {
            $colorWarn.addClass('hide');
        } else {
            error += 1;
            $colorWarn.removeClass('hide');
        }

        if (error > 0) {
            $('html,body').animate({
                scrollTop: ($('.prod-select .content').offset().top) - 120
            }, 200);
            return false;
        }

        $.each($('.product-data'), function (index, element) {
            var $element = $(element);
            productBaseData[$element.attr('name')] = $element.val();
        });

        products = colors.map(i => {
            // console.log(i)
            return Object.assign({}, baseData, {
                data: Object.assign({}, productBaseData, {'Color': i})
            })
        })

        // console.log(products); return 0;

        return {products}
    },

    addCart() {
        let $productSelect = $('.p-prod');

        $productSelect.on('click', '.js-cart-btn,.js-paypal-btn', function (e) {
            e.preventDefault();
            let $this = $(this);
            let data;

            if ($d.product.detail.type === 'fabricsample') {
                data = shopJS.prod.getFabricToCartData()
            } else {
                data = shopJS.prod.getNormalToCartData()
            }

            if (!data) {
                return
            }

            $('#loadingModal').modal('show');
            const $addCartModal = $('#addCartModal');

            if ($this.hasClass('btn-add-cart')) {
                shopJS.report.monitor({label: 'product page', action: 'click-Add to bag button'})
            } else if ($this.hasClass('btn-paypal')) {
                shopJS.report.monitor({label: 'product page', action: 'click-Paypal button'})
            }
            // facebook pixel
            if (shopJS.facebook.status) {
                let sell_price = shopJS.prod.getProductSellPrice();
                let currency = shopJS.prod.getCurrentCurrency();
                let sn = shopJS.common.getSnFromUrl();
                shopJS.facebook.addToCart(sell_price, currency, [sn], 'product');
            }
            // facebook pixel over

            $u.http.post('/api/product/cart', data).then(res => {
                let result = res.data;
                // console.log(result);
                if (result.code === 200) {
                    shopJS.common.setCartNumber(result.data.cart.goodsNumber);
                    $('header').addClass('in');
                    $addCartModal.find('.modal-content .body-wrap').html(result.data.modal);
                    $addCartModal.modal('show');
                    setTimeout(function () {
                        $('#loadingModal').modal('hide');
                    }, 500);
                    shopJS.prod.reportGaAddToCart(data.qty);
                } else if (result.code === 400) {
                    $('#loadingModal').modal('hide');
                    alert(result.data.message);
                }
            })
        });
    },

    /* 检查到现货商品 */
    checkInStock() {

        // 全品 stock
        if($d.product.isAllInStock){
            $('.in-stock-tips').removeClass('hide').text('In Stock');
            shopJS.prod.setETAInfo($d.product.detail.eta, true)
            return;
        }

        // 无现货商品跳过
        if (!$d.product.isInStock) {
            return null
        }

        // 有现货商品
        let scene = $d.product.curMultiTab === 'in-stock' ? 'in-stock' : 'normal';
        let $color = $('.select-color.' + scene + ' .color-list li.selected');
        let $size = $('.select-size.' + scene + ' .size-list-wrap li.selected');

        /*当前tab的color、size已选*/
        if ($color.length && $size.length) {
            let color = $color.find('a').data('value');
            let size = $size.attr('value');

            let specs = $d.product.detail.specs.filter(i => {
                return i.color === color && i.size === size;
            })

            if (specs.length) {
                $('.in-stock-tips').removeClass('hide').text('In Stock');
                shopJS.prod.setETAInfo($d.product.detail.eta, true)
                return specs[0]
            }
        }

        $('.in-stock-tips').addClass('hide').text('');
        shopJS.prod.setETAInfo($d.product.detail.eta)

        return null;
    },

    bindBuyMatchProd() {
        const $box = $('.match-list-box');
        const $btns = $box.find('.mp-add-to-cart');
        // shopJS.common.axSetup();

        $btns.on('click', function () {
            let $this = $(this);

            if (!$this.data('allow-add-bag')) {
                shopJS.report.monitor({label: 'product page', action: 'match-looks view-detail'}, () => {
                    $this.parents('.item').find('.img-wrap')[0].click();
                })
                return;
            }
            shopJS.report.monitor({label: 'product page', action: 'match-looks add-to-cart'})

            let pid = $this.data('pid');
            let attr = {};
            let data = {};
            let select_color = $this.data('select-color');
            if (!select_color || 'undefined' == select_color) {
                select_color = 'As picture';
            }
            attr['Color'] = select_color;
            attr['Img_key'] = 0;
            data.data = attr;
            data.pid = pid;
            data.qty = 1;
            data.isAjax = 1;

            let url = '/api/product/cart';
            $u.http.post(url, data).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    shopJS.common.success('Added Successfully!', 3000, function () {
                        shopJS.static.baseStatic();
                        // window.location.reload()
                    });
                } else {
                    shopJS.common.alert('Failed,Please try again');
                }

            }).catch((err) => {
                let res = err.response;
                // console.log(err);
                // console.log(res);
                shopJS.common.alert('Failed,Please try again');
            })
        });
    },

    review() {
        $('.js-review-more').on('click', function (e) {
            var $this = $(this);
            if ($this.hasClass('more')) {
                $this.removeClass('more').html('See More');
                $('.review-more').addClass('hide');
            } else {
                $this.addClass('more').html('Less');
                $('.review-more').removeClass('hide');
            }
        });
    },

    modalOpen() {
        $('#size-chart,#custom-modal,#bustle-modal').on('shown.bs.modal', function () {
            let $iframe = $(this).find('.iframe-wrap iframe')
            if (!$iframe.attr('src')) {
                $iframe.attr('src', $iframe.data('src'));
                shopJS.prod.loadingIframe($iframe)
            }
        })
    },

    loadingIframe($iframe) {
        const $t = $iframe;
        const $parent = $t.parent('.iframe-wrap')
        if ($parent.length < 1) {
            return;
        }

        let originW = $t[0].width;
        let originH = $t[0].height;
        let pW = $parent.width();
        $t[0].width = pW;
        $t[0].height = pW / originW * originH;
    },

    activityCountdown() {
        var $clocks = $('.prod-activity .clock')

        $clocks.each(function () {
            var $this = $(this);
            var deadline = $this.data('deadline');
            var $hour = $this.find('.hour');
            var $minute = $this.find('.minute');
            var $second = $this.find('.second')

            if (!deadline || deadline < 3) {
                return
            }
            var clock = new Countdown(deadline, {
                everySecondFn: function (date) {
                    let html = '';
                    if (Number(date.day) > 0) {
                        html += `<span class="day">${date.day}Day </span> &nbsp;&nbsp;`
                    }
                    html += ` <span class="hour">${date.hourToDay}</span> :
                              <span class="minute"> ${date.minute}</span> : 
                              <span class="second"> ${date.second} </span>`;
                    $this.html(html);
                },
                finalFn: function (date) {
                    // console.log('final')
                }
            });

            clock.start()
        })
    },

    setETAInfo(eta, isInStock = false) {
        let inStockEtaInfo = $('.eta-box p.in-stock');
        let normalEtaInfo = $('.eta-box p.normal');

        if (isInStock) {
            inStockEtaInfo.removeClass('hide')
            normalEtaInfo.addClass('hide');
        } else {
            normalEtaInfo.removeClass('hide')
            inStockEtaInfo.addClass('hide');
        }

        if (eta) {
            let etaStr = '';
            let etaDateRange = []
            let etaDateRangeString = ''
            $.each(eta, function (k, v) {
                if ((isInStock && v.key !== 'in-stock') || (!isInStock && v.key === 'in-stock')) {
                    return;
                }
                let shipClass = v.disabled ? 'disabled' : '';
                etaStr += '<p class="order-item ' + shipClass + '">';
                etaStr += '- ' + v.name + ' : ' + v.arrival_date;
                etaDateRange.push(v.arrival_time)
                if (v.disabled) {
                    etaStr += '<span class="disabled-tip">' + v.message + '</span>';
                }
                etaStr += '</p>';
            })

            // console.log(etaDateRange)

            etaDateRange.sort()

            // console.log(etaDateRange)

            if (etaDateRange.length) {
                $.each(etaDateRange, function (k, v) {
                    let date = new Date(v * 1000);
                    etaDateRangeString += date.toLocaleDateString("en-US", {month: "short", day: "2-digit"}) + '-';
                })
            }
            etaDateRangeString = etaDateRangeString.substring(0, etaDateRangeString.length - 1)
            // console.log(etaDateRangeString)

            $('.eta-date-range').html(etaDateRangeString);
            $('.ship-arrival').html(etaStr);
        }
    },

    staticPromotionHtml(promotions) {
        var $promotion = $('.js-product-promotions');
        var $promotionList = $('.js-product-promotion-list');
        if (promotions.length > 0) {
            var promotionStr = '';
            $.each(promotions, function (k, promotion) {
                let isShowStatus;

                if (promotion.tags && typeof promotion.tags.productAltHide !== 'undefined') {
                    isShowStatus = String(promotion.tags.productAltHide);
                }

                if (isShowStatus === '' || isShowStatus === '3') {
                    return
                }

                var deadline = Math.floor(promotion.end_time - (new Date().getTime()) / 1000);
                if (deadline > 0) {
                    var url = promotion.url ? promotion.url : null;
                    var data = $u.getDetailData(deadline);
                    promotionStr += '<li class="item">';
                    promotionStr += url ? '<a class="a-link" href="' + url + '">' : '<div class="a-link">';
                    promotionStr += '<div class="desc">' + promotion.alt + '</div>';
                    if (isShowStatus !== '2') {
                        promotionStr += '<div class="clock notranslate" data-deadline="' + deadline + '">' +
                            '                 <span class="hour">' + data.hour + '</span> :' +
                            '                 <span class="minute">' + data.minute + '</span> :' +
                            '                 <span class="second">' + data.second + '</span>' +
                            '             </div>';
                    }
                    promotionStr += url ? '</a >' : '</div>';
                    promotionStr += '</li>';
                }
            })
            $promotionList.html(promotionStr);
            shopJS.prod.activityCountdown();
            $promotion.removeClass('hide');
        } else {
            $promotion.addClass('hide');
            $promotionList.html('');
        }
    },

    limitShowColor() {
        const $list = $('.color-list-wrap .color-list');
        const LIMIT_COLORS = 20;

        $list.each(function () {
            let $listWrap = $(this).parent();
            let $btnMore = $(this).siblings('.btn-more');
            let listColors = $(this).find("li.animate_click").length;
            // console.log(listColors);

            if (listColors > LIMIT_COLORS) {
                $listWrap.addClass('limit');
                $btnMore.removeClass('hide');
                $btnMore.on('click', function () {
                    $listWrap.removeClass('limit');
                    $(this).remove();
                })
            }
        })
    },

    bindToReviews() {
        const $btnToReview = $(".comment-summary");
        $btnToReview.on('click', function () {
            let e = $(".review-box");
            if (e.length < 1) {
                return;
            }
            $("html,body").animate({
                scrollTop: e.offset().top - 80
            }, 300)
        });
    },

    bindLike() {
        const $btnLike = $('.js-prod-like');
        $btnLike.on('click', function (e) {
            e.preventDefault();
            const $this = $(this);
            if ($this.hasClass('liked')) {
                shopJS.like.ajaxRemoveLike($btnLike).then((res) => {
                    setCount(res)
                    $btnLike.find('.text').text('like');
                });
            } else {
                shopJS.like.ajaxLike($btnLike).then((res) => {
                    setCount(res)
                    $btnLike.find('.text').text('liked');
                });
            }
        });

        function setCount(res) {
            let limitValue = 3
            if (!res || !res.data) return;
            if (res.data.data > limitValue) {
                $btnLike.find('.count').text(`(${res.data.data})`);
            } else {
                $btnLike.find('.count').text('');
            }
        }
    },

    sendShareEmail() {
        $('.js-share-email').on('click', function () {
            let client = shopJS.common.getClientInfo();
            let siteName = $('meta[property="og:site_name"]').attr('content');
            let shareLink = window.location.href;
            let prodInfo = shopJS.prod.getProductInfo();
            let $email = '';
            let subject = encodeURIComponent(`Suits you!I saw this at ${siteName} and thought you would like it.`);
            let body;
            if (client === 'Safari 14') {
                body = encodeURIComponent(`
            ${prodInfo.name}
            ${shareLink} 
            `);
            } else {
                body = encodeURIComponent(`
            ${prodInfo.name}\n
            ${shareLink} 
            `);
            }

            document.location.href = "mailto:" + $email + "?subject=" + subject + "&body=" + body
        })
    },

    getProductInfo() {
        return {
            name: $('meta[property="og:title"]').attr('content'),
            id: $('meta[property="product:retailer_item_id"]').attr('content'),
            price: $('meta[property="product:price:amount"]').attr('content'),
        }
    },

    reportGaDetail() {
        let product = shopJS.prod.getProductInfo();
        // console.log(product);
        shopJS.report.detail([product])
    },

    reportGaAddToCart(quantity) {
        let product = shopJS.prod.getProductInfo();
        product.quantity = quantity;
        shopJS.report.addToCart([product])
    }
};
