shopJS.checkout = {
    addressSelected: $(".js-address-selected"),
    addressList: $(".js-address-list"),
    summaryTotal: $(".js-summary-total"),
    summarySavingAmount: $(".js-saving-amount"),
    summaryRush: $(".js-rush-summary"),
    summaryRushFee: $(".js-summary-rush-fee"),
    summaryShippingFee: $(".js-summary-shipping-fee"),
    summaryShippingProtection: $('.js-shipping-protection'),
    summaryShippingProtectionFee: $('.js-shipping-protection-fee'),

    init: function () {
        // shopJS.common.axSetup();
        shopJS.checkout.addressInit();
        shopJS.checkout.shippingInit();
        shopJS.checkout.rushInit();
        shopJS.checkout.placeOrder();
        shopJS.checkout.pointConsume();
        shopJS.checkout.initCoupon();
        shopJS.cart.cartItemDetail();

        // add facebook pixel
        if (shopJS.facebook.status) {
            shopJS.facebook.initCheckout();
        }
        // add facebook pixel
    },

    addressInit: function () {
        shopJS.ckAddress.init();

        // address show
        $(".js-address-show").on("click", function (e) {
            e.preventDefault();
            e.stopPropagation();
            // show address list for login user
            if (shopJS.checkout.addressList.length > 0) {
                shopJS.ckAddress.addressListModal.modal("show");
            } else {
                // no login user
                shopJS.ckAddress.setAddressInput($(this).data('addressjson'));
                shopJS.ckAddress.addressModalShow("reload");
            }
        })
    },

    shippingInit: function() {
        if (shopJS.ckShipping.shippingListBox.length) {
            shopJS.ckShipping.init();
        }
    },

    rushInit: function () {
        if (shopJS.ckRush.rushListBox.length) {
            shopJS.ckRush.init();
        }
    },

    updateRush() {
        shopJS.ckRush.rushList.each(function () {
            const $this = $(this);
            shopJS.ckRush.ajaxGetRushFee($this, 'init');
        });
    },

    placeOrder: function () {
        $('.btn-place-order').on('click', function (e) {
            e.preventDefault();

            var $this = $(this);
            if ($this.hasClass('disabled')) {
                return false;
            }

            shopJS.report.checkout({step: 5})

            if (shopJS.checkout.checkPayment()) {
                $('#loadingModal').modal('show');
                shopJS.checkout.ajaxCheckoutSubmit($this);
                $this.addClass('disabled');
            }
        });
    }

    , checkPayment: function () {
        var $message = $('.payment-message ');
        var $placeOrder = $('.btn-place-order');
        // console.log(shopJS.ckAddress.getAddress());

        if (!shopJS.ckAddress.getAddress()) {
            $message.html('Please select or input your delivery address!').removeClass('hide');
            $placeOrder.addClass('disabled');
            return false;
        }

        if (shopJS.ckShipping.getShipping() === undefined || shopJS.ckRush.getRush() === undefined) {
            $message.html('Please select shipping method and arrival date!').removeClass('hide');
            $placeOrder.addClass('disabled');
            return false;
        }

        $message.addClass('hide').html('');
        $placeOrder.removeClass('disabled');
        return true;
    }

    , ajaxCheckoutSubmit: function ($this) {
        let $checkoutMessage = $('.payment-message');

        let data = shopJS.ckAddress.getAddress();
        data.shipping_method = shopJS.ckShipping.getShipping();
        data.rush_level = shopJS.ckRush.getRush();
        data.cartItemIds = $('#cartItemIds').val();

        $u.http.post('/checkout/submit', data).then(res => {
            let result = res.data;

            if (result.code === 200 && result.data.success) {
                var json = {time: new Date().getTime()};
                window.history.replaceState(json, "", result.data.paymentUrl + '/history');
                window.location.href = result.data.paymentUrl;
                return;
            }

            if (result.data.message && !result.data.success) {
                $checkoutMessage.html(result.data.message);
                $checkoutMessage.removeClass('hide');
                $this.removeClass('disabled');
                $('#loadingModal').modal('hide');
            } else {
                $checkoutMessage.addClass('hide');
            }
        }).catch()
    },

    pointConsume: function () {
        var $point = $('.js-point-consume');
        if ($point.length) {
            $point.on('click', function () {
                if ($point.prop('checked')) {
                    shopJS.checkout.ajaxPointConsume($point.val());
                } else {
                    shopJS.checkout.ajaxPointRemove();
                }
            });
        }
    },

    getCartSummary() {

        let url = '/api/checkout/summary?'
        let address = shopJS.ckAddress.getAddress();

        if (address) {
            url += `&delivery_cid=${address.delivery_cid}`;
        }

        let rushLevel = $('.js-rush-list li.on').data('value');

        if (rushLevel !== undefined) {
            url += `&rush_level=${rushLevel}`;
        }

        let shippingMethod = $('.js-shipping-list li.on').data('value');

        if (shippingMethod !== undefined) {
            url += `&shipping_method=${shippingMethod}`;
        }

        $u.http.get(url).then(res => {
            let $payIn4Warp = $('.pay-in-4-wrap');
            const data = res.data.data;
            let summary = data.cart.summary;

            /* update discount */
            const $subTotal = $('li.li_subtotal');
            let html = '';
            $('li.li_discount').remove();
            summary.discounts.forEach(i => {
                html += `<li class="li_discount">
                    <span>${i.type}:</span>
                    <span class="notranslate">${i.value}</span>
                </li>`
            })
            $subTotal.after(html);

            /* update shipping */
            html = ''
            if(summary.shippingFeeOriginal_c !== summary.shippingFee_c){
                html += '<del>' + summary.shippingFeeOriginal_c + '</del>'
            }
            html += '<span>' + summary.shippingFee_c + '</span>'
            shopJS.checkout.summaryShippingFee.data('value', summary.shippingFee);
            shopJS.checkout.summaryShippingFee.html(html);


            /* update shipping protection Fee */
            if (summary.shippingProtectionFee) {
                shopJS.checkout.summaryShippingProtectionFee.data('value', summary.shippingProtectionFee);
                shopJS.checkout.summaryShippingProtectionFee.html(summary.shippingProtectionFee_c);
                shopJS.checkout.summaryShippingProtection.removeClass('hide');
            } else {
                shopJS.checkout.summaryShippingProtection.addClass('hide');
            }

            /* update rush */
            if(summary.rushFee){
                shopJS.checkout.summaryRushFee.data('value', summary.rushFee);
                shopJS.checkout.summaryRushFee.text(summary.rushFee_c);
                shopJS.checkout.summaryRush.removeClass('hide');
            }else{
                shopJS.checkout.summaryRush.addClass('hide');
            }

            /* update total */
            shopJS.checkout.summaryTotal.html(summary.goodsAmount_c);
            shopJS.checkout.summarySavingAmount.text(summary.totalSavedAmount_c);

            /* update pay-in-4 */
            if ($payIn4Warp.length) {
                let total = data.cart.summary.goodsAmount;
                $payIn4Warp.removeClass('hide').find('.price_one').text(Math.ceil(total / 4 * 100) / 100);
            }

        }).catch(err => {
            console.log(err)
        })
    },

    initCoupon() {
        $('#coupon-code-input-wrap .btn-coupon').on('click', function () {
            let inputTarget = $('#coupon-code-input-wrap .ipt-coupon');
            let appliedCouponCode = $.trim($(this).data('code'));
            let messageBox = $("#coupon-code-input-wrap .coupon-tips");
            let data = {};

            // remove action
            if(appliedCouponCode){
                data.couponCode = appliedCouponCode;
                data.aid = $.trim($(this).data('aid'));
                data.choose = false;
                shopJS.checkout.ajaxCartCoupon(data);
            }else{ // apply action
                let inputCouponCode = inputTarget.val().trim();
                if (!inputCouponCode) {
                    messageBox.html("Please input coupon code!")
                        .removeClass("success")
                        .addClass("error")
                        .removeClass("hide")
                    return false;
                }
                shopJS.report.checkout({step: 4});

                data.couponCode = inputCouponCode;
                data.choose = true;
                shopJS.checkout.ajaxCartCoupon(data);
            }
        });
    },

    ajaxCartCoupon(data) {
        let messageBox = $("#coupon-code-input-wrap .coupon-tips");
        data.cartItemIds = $('#cartItemIds').val();

        $u.http.post('/checkout/ajaxCouponCheck', data).then(res => {
                let result = res.data;
                if (result.code === 200) {
                    if (result.data.success) {
                        const $couponInput = $('#coupon-code-input-wrap .ipt-coupon');
                        const $couponButton = $('#coupon-code-input-wrap').find('.btn-coupon');
                        const coupons = result.data.coupons.filter(i => i.userChoose === true)
                        if (coupons.length > 0) {
                            let coupon = coupons[0];
                            $couponInput.val(coupon.number)
                                .attr('readonly', true)
                                .addClass('readonly');
                            $couponButton.data('id', coupon.aid);
                            $couponButton.data('code', coupon.number);
                            $couponButton.html('REMOVE');
                            messageBox.removeClass('error')
                            messageBox.addClass('success')
                            messageBox.html('Coupon code applied successfully.')
                            messageBox.removeClass('hide')
                        } else {
                            $couponInput.val('')
                                .attr('readonly', false)
                                .removeClass('readonly')
                            $couponButton.html('APPLY')
                                .data('id', '')
                                .data('code', '');
                            messageBox.addClass('hide')
                            messageBox.html('')
                        }
                        shopJS.checkout.getCartSummary();
                    } else {
                        messageBox.html("The coupon code is not usable")
                            .removeClass("success")
                            .addClass("error")
                            .removeClass("hide")
                    }
                } else {
                    messageBox.html(result.data.message)
                        .removeClass("success")
                        .addClass("error")
                        .removeClass("hide")
                }
            }
        )
    },

    ajaxPointConsume(point) {
        $u.http.post('/checkout/cart-point-consume', {
            point: point,
        }).then(res => {
            let result = res.data;
            let $message = $('.js-referal-message');
            if (result.code === 200 && result.data.success) {
                $message.empty();
                shopJS.checkout.getCartSummary();

            } else {
                $message.html(result.data.message);
            }
        }).catch()
    },

    ajaxPointRemove() {
        $u.http.post('/checkout/cart-point-remove', {}).then(res => {
            let result = res.data;
            let $message = $('.js-referal-message');
            if (result.code === 200 && result.data.success) {
                $message.empty();
                shopJS.checkout.getCartSummary();
            } else {
                $message.html(result.data.message);
            }
        }).catch()
    },
};