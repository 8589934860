shopJS.cart = {
    init: function () {
        var $cart = $('.cart-list');
        // shopJS.common.axSetup();
        shopJS.cart.shoppingCart();
        shopJS.cart.cartRemove($cart);
        shopJS.cart.cartQtyEdit($cart);
        shopJS.cart.cartSubmit();
        shopJS.cart.cartItemDetail();
        shopJS.cart.cartSubtotalSwitch();
        shopJS.googleAds.googleAds('cart', null, shopJS.cart.getCartTotalAmount());
        shopJS.cart.buildGiftCountdown();
        shopJS.cart.cartGift();
        shopJS.cart.giftSelect();
        shopJS.cart.initCoupon();
    },

    cartItemDetail: function () {
        let sizeInfoSimple = $(".size-info-simple");
        sizeInfoSimple.on('click', function () {
            let sizeInfoDetail = $(this).parent().find(".size-info-detail")
            let icon = $(this).find(".details-indicator")
            let status = sizeInfoDetail.hasClass('hide');
            if (status) {
                sizeInfoDetail.removeClass('hide');
                icon.addClass("open")
            } else {
                sizeInfoDetail.addClass('hide');
                icon.removeClass("open")
            }
        })
    },


    cartSubtotalSwitch: function () {
        let totalTips = $(".total-tips");
        let subtotalDetail = $(".subtotal-detail")
        let subtotalSwitch = $(".subtotal-switch")
        totalTips.on('click', function () {
            let status = subtotalDetail.hasClass('hide');
            if (status) {
                subtotalDetail.removeClass('hide');
                subtotalSwitch.addClass("open")
            } else {
                subtotalDetail.addClass('hide');
                subtotalSwitch.removeClass("open")
            }
        })
    }


    , getCartTotalAmount: function () {
        return $('.js-cart-total').text();
    }

    , cartRemove: function ($cart) {
        var $cartConfirm = $('#cart-delete-confirm');

        $cart.on('click', '.js-cart-delete', function (e) {
            e.preventDefault();
            $cartConfirm.find('.ok').data('itemid', $(this).data('itemid'));
            $cartConfirm.modal('show');
        });

        $cartConfirm.on('click', '.ok', function (e) {
            e.preventDefault();
            if ($(this).data('itemid')) {
                shopJS.cart.ajaxCartRemove($(this));
            }
        })
    }

    , cartQtyEdit: function ($cart) {
        $cart.on('click', '.js-item-edit', function (e) {
            e.preventDefault();
            shopJS.cart.ajaxItemQtyEdit($(this));
        });
    }

    , cartSubmit: function () {
        $('.js-cart-summit').on('click', function (e) {
            e.preventDefault();
            shopJS.report.monitor({label: 'cart page', action: 'click-checkout button'})
            $('.js-cart-form').submit();
        });
    }

    , ajaxItemQtyEdit: function ($this) {
        var cartItemId = $this.data('itemid');
        var $item = $this.closest('li');
        var $qty = $item.find('.p-qty .value');
        var qty = parseInt($qty.text());
        var sellMax = $qty.data('sell-max');
        var sellMin = $qty.data('sell-min');
        var type = $this.data('type');

        if (type === 'decrease' && qty <= 1) {
            return false;
        }

        if (qty < 1) {
            $qty.text('1');
            qty = 1;
        }

        if (type === 'decrease' && sellMin > 0 && qty === sellMin) {
            return false;
        }

        if (type === 'increase' && sellMax > 0 && qty === sellMax) {
            return false;
        }

        var url = null;
        switch (type) {
            case 'increase' :
                url = '/cart/increase';
                break;
            case 'decrease' :
                url = '/cart/decrease';
                break;
            default :
                break;
        }

        if (url) {
            $u.http.post(url, {
                cartItemId: cartItemId,
                qty:        qty
            }).then(res => {
                let result = res.data;
                if (result.code === 200) {
                    $qty.text(result.data.itemQty);
                    shopJS.common.setCartNumber(result.data.number);
                    shopJS.cart.reportGaForCart({type, $item});
                    window.location.reload();
                } else if (result.code === 400) {
                    alert(result.data.message);
                }
            }).catch(err => {
            })
        }
    }

    , ajaxCartRemove($this) {
        var cartItemId = $this.data('itemid');
        var $item = $this.parents('.cart-item');
        var qty = $item.find('.p-qty .value').text();

        $u.http.post('/cart/delete', {
            cartItemId: cartItemId
        }).then(res => {
            let result = res.data;
            if (result.code === 200) {
                shopJS.cart.reportGaForCart({type: 'decrease', $item: $item, qty: parseInt(qty)})
                window.location.reload();
            }
        }).catch(err => {

        })
    },

    shoppingCart: function () {
        const $cartListBox = $('.cart-list-ul');

        $cartListBox.on('change', '.cart-item .tips', function () {
            let data = {
                type:    $(this).data('type'),
                id:      $(this).data('id'),
                comment: $(this).val()
            };
            $u.http.post('/cart/comment/update', data).then(res => {
            })
        });
    },

    buildGiftCountdown() {
        // let itemList = $('.gift-item-countdown');

        $('.gift-item-countdown').each(function (index) {
            let $this = $(this);
            let deadline = $this.data('deadline');

            // console.log(deadline);
            // console.log(typeof deadline);

            if (!deadline || deadline < 3) {
                return
            }
            const clock = new Countdown(deadline, {
                everySecondFn: function (date) {
                    $this.text(`${date.minute}m${date.second}s`);
                },
                finalFn:       function (date) {
                    console.log('final')
                    // window.location.reload();
                }
            });

            clock.start()
        })
    },

    cartGift:                   function () {
        let $choseGiftBtn = $('.js-chose-gift-btn');
        $choseGiftBtn.on('click', function (e) {
            e.preventDefault();
            let $this = $(this);
            shopJS.cart.ajaxGetCartProductGiftList($this);
        });

        let $choseGiftModal = $('#ChoseGiftModal');
        $choseGiftModal.on('click', '.js-chose-gift-submit', function (e) {
            e.preventDefault();
            shopJS.cart.ajaxSaveCartProductGift();
        });

        $choseGiftModal.on('click', '.js-cart-item-id', function () {
            shopJS.cart.changeCartGiftTitle();
        });
    },
    ajaxGetCartProductGiftList: function ($this) {
        let $choseGiftModal = $('#ChoseGiftModal');
        let cartItemId = $this.data('value');

        if (!cartItemId) {
            alert('error');
            return;
        }

        $u.http.post('/cart/itemGiftList', {
            cartItemId: cartItemId,
        }).then(res => {
            let result = res.data;
            if (result.code === 200) {
                $choseGiftModal.find('.modal-content').html(result.data.modal);
                $choseGiftModal.find('.js-cart-item-id').val(cartItemId);
                shopJS.cart.changeCartGiftTitle();
                $choseGiftModal.modal('show');
            } else if (result.code === 400) {
                alert(result.data.message);
            }
        }).catch(err => {
        })
    },

    changeCartGiftTitle: function () {
        let giftLength = $('.js-cart-item-id').length;
        let giftCheckedLength = $('.js-cart-item-id:checked').length;

        $('.js-can-chose-total').text(giftLength);
        $('.js-can-chose-count').text(giftCheckedLength);

    },

    ajaxSaveCartProductGift: function () {
        let $choseGiftModal = $('#ChoseGiftModal');
        let cartItemId = $choseGiftModal.find('.js-cart-item-id:checked').val();

        if (!cartItemId) {
            alert('Please select at least one gift.');
            return;
        }

        $u.http.post('/cart/saveCartProductGift', {
            cartItemId: cartItemId,
        }).then(res => {
            let result = res.data;
            if (result.code === 200) {
                location.reload();
            } else {
                alert('Failed to add to bag, please try again.');
            }
        }).catch()
    },

    giftSelect() {
        var $cartListBox = $('.js-cart-gift-box');

        $cartListBox.on('click', '.btn-reselect', function () {
            $('.show-gift-box').addClass('hide');
            $('.select-gift-box').removeClass('hide');
        });

        $cartListBox.on('click', '.btn-shopping', function () {
            const $this = $(this);
            const $modalGiftBox = $this.parents('#MultiGiftModal');

            $u.http.post('/cart/chose/cartGift', {pid: $(this).data('pid')}).then(res => {
                let result = res.data;
                if (result.code == 200) {
                    location.reload();
                }
            }).catch()
        })
    },

    alsoLikeSelectProduct() {
        let $inputs = $('.also-like-box .list .item input');
        let $btnConfirm = $('.also-like-box .btn-confirm');

        shopJS.cart.ajaxGetSelectedComboPromotion();

        $inputs.on('change', function () {
            shopJS.cart.ajaxGetSelectedComboPromotion();
        });

        $btnConfirm.on('click', function () {
            shopJS.cart.ajaxSubmitCombo();
        });
    },

    getComboSelectedPids: function () {
        let $inputs = $('.also-like-box .list .item input');
        let $selectedInputs = $inputs.filter(':checked');

        let selectedProducts = $.makeArray($selectedInputs.map(function () {
            return Number($(this).val())
        }));

        return selectedProducts;
    },

    ajaxGetSelectedComboPromotion: function () {
        let $discount = $('.also-like-box .summary .line1');
        let $packageTotal = $('.also-like-box .summary .package_total');

        let selectedProducts = shopJS.cart.getComboSelectedPids();

        $u.http.post('/api/cart/combo/change', {
            pids: selectedProducts,
        }).then(res => {
            let result = res.data;
            if (result.code === 200) {
                if (result.data.select_benefit_value != undefined) {
                    $discount.show();

                    let discountOff = Math.round((1 - result.data.select_benefit_value) * 10000) / 100;
                    $discount.html(`${selectedProducts.length} pc${selectedProducts.length > 1 ? 's' : ''}
                                    <strong>${discountOff}% off</strong> (<span class="notranslate">-${result.data.benefit_price_c}</span>)`);
                } else {
                    $discount.hide();
                }
                $packageTotal.text(result.data.new_price_c);
            } else {
                alert('Failed!');
            }
        }).catch()
    },

    ajaxSubmitCombo: function () {
        let selectedProducts = shopJS.cart.getComboSelectedPids();
        if (selectedProducts.length < 1) {
            alert('Please select at least one product !');
            return;
        }

        $u.http.post('/api/cart/combo/submit', {
            pids: selectedProducts,
        }).then(res => {
            let result = res.data;
            if (result.code === 200) {
                alert('Added to bag.');
                location.reload();
            } else {
                alert('Failed to add to bag, please try again.')
            }
        }).catch()
    },


    initCoupon() {
        $('#coupon-code-input-wrap .btn-coupon').on('click', function () {
            let inputTarget = $('#coupon-code-input-wrap .ipt-coupon');
            let appliedCouponCode = $.trim($(this).data('code'));
            let messageBox = $("#coupon-code-input-wrap .coupon-tips");
            let data = {};

            // remove action
            if(appliedCouponCode){
                data.couponCode = appliedCouponCode;
                data.aid = $.trim($(this).data('aid'));
                data.choose = false;
                shopJS.cart.ajaxCartCoupon(data);
            }else{ // apply action
                let inputCouponCode = inputTarget.val().trim();
                if (!inputCouponCode) {
                    messageBox.html("Please input coupon code!")
                        .removeClass("success")
                        .addClass("error")
                        .removeClass("hide")
                    return false;
                }
                data.couponCode = inputCouponCode;
                data.choose = true;
                shopJS.cart.ajaxCartCoupon(data);
            }
        });
    },

    ajaxCartCoupon(data) {
        let messageBox = $("#coupon-code-input-wrap .coupon-tips");
        data.couponCode = $.trim(data.couponCode);

        $u.http.post('/checkout/ajaxCouponCheck', data).then(res => {
                let result = res.data;
                if (result.code === 200) {
                    if (result.data.success) {
                        window.location.reload();
                    } else {
                        messageBox.html("The coupon code is not usable")
                            .removeClass("success")
                            .addClass("error")
                            .removeClass("hide")
                    }
                } else {
                    messageBox.html(result.data.message)
                        .removeClass("success")
                        .addClass("error")
                        .removeClass("hide")
                }
            }
        )
    },

    /**
     *
     * @param  { { $item,type,qty } } data
     * @param  { function|undefined } callback
     * @return { void }
     *
     * */
    reportGaForCart(data) {
        let product = {
            name:  data.$item.find('.p-name a').text(),
            id:    data.$item.find('.p-name').data('sn'),
            price: data.$item.find('.p-name').data('price'),
        }
        product.quantity = data.qty || 1;

        if (data.type === 'decrease') {
            shopJS.report.removeFormCart([product]);
        } else if (data.type === 'increase') {
            shopJS.report.addToCart([product]);
        }
    }
};
